<template>
  <div class="food zhong">
    <hd-com></hd-com>
    <div class="banner">
      <div class="banner-text">
        <h3>食疗药膳</h3>
        <p>SHI LIAO YAO SHAN</p>
      </div>
    </div>
    <div class="content">
      <div class="content-box clearfix">
        <div class="content-left">
          <div class="hot-search">
            <p>热门搜索</p>
            <ul class="clearfix">
              <li v-for="hotsearch in hots.slice(0, 6)" :key="hotsearch.index" @click="toFoodDetail(hotsearch.hashKey)">
                <span>{{ hotsearch.foodMaterialPrescriptionName }}</span>
              </li>
            </ul>
          </div>
          <div class="sidenav">
            <h3 @click="searchFood(0)">全部药膳</h3>
            <el-menu class="el-menu-vertical-demo" unique-opened>
              <template>
                <el-submenu v-for="(sideNav, index) in foodNavList" :key="index" :index="index + ''">
                  <template slot="title">
                    <span>{{ sideNav.categoryName }}</span>
                  </template>
                  <div class="subtitle">
                    <el-menu-item @click="searchFood(sideNav.categoryId)" class="">
                      <template slot="title"> 全部 </template>
                    </el-menu-item>
                    <el-menu-item v-for="(subItem, i) in sideNav.children" :key="i" :index="subItem.categoryId + ''" @click="searchFood(subItem.categoryId)">
                      <template slot="title">
                        {{ subItem.categoryName }}
                      </template>
                    </el-menu-item>
                  </div>
                </el-submenu>
              </template>
            </el-menu>
          </div>
        </div>
        <div class="content-right">
          <div class="result-tit">
            <p>
              为您找到 <span>{{ total }}</span
              ><em>个结果</em>
            </p>
          </div>
          <ul class="food-list">
            <li class="food-item resule-item clearfix" v-for="(foodDetailItem, i) in foodDetailList">
              <div @click="toFoodDetail(foodDetailItem.hashKey)" class="left">
                <h3 class="food-tit">
                  {{ foodDetailItem.foodMaterialPrescriptionName }} <span v-if="category(foodDetailItem)">{{ category(foodDetailItem) }}</span>
                </h3>
                <p v-if="foodDetailItem.source">
                  出自： <em>{{ foodDetailItem.source }}</em>
                </p>
              </div>
              <div class="resule-star right">
                <p>
                  <span class="on-cpt" @click="onCompatibility(foodDetailItem)">加入配伍栏</span>
                </p>
              </div>
              <!-- <div class="sc-btn">
                                <i class="el-icon-star-off" @click="onCollection(foodDetailItem)"></i>
                            </div> -->
            </li>
          </ul>
          <div id="page">
            <el-pagination background layout="prev, pager, next,total" :page-size="15" @current-change="handleCurrentChange" :current-page.sync="currentPage" :total="total"> </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <ft-com></ft-com>
    <rt-bar></rt-bar>
    <UpgradeMsgBox ref="upgradeMsgBox" @currentlyAvailable="getCurrentlyAvailable" :fullPath="$router.currentRoute.fullPath"></UpgradeMsgBox>
  </div>
</template>

<script>
import { Food } from "../../components/Drug/Food";
import hdCom from "../../components/header";
import ftCom from "../../components/footer";
// import rtBar from "../../components/right";
import { Collection } from "../../components/collection/Collection";
import { addCompare, getCompatibilityList } from "../../utils/Compatibility";
var searchId = 0;
var pageIndex = 1;
var categoryId = 0;
var keyword = "";
export default {
  name: "Food",
  components: {
    hdCom,
    ftCom,
    // rtBar,
  },
  data() {
    var food = new Food(this.TokenClient, this.Services.Drug);
    var collection = new Collection(this.TokenClient, this.Services.Statics);
    return {
      checkedhot: [],
      hots: [],
      foodNavList: [],
      foodDetailList: [],
      total: 0,
      currentPage: 1, //初始页
      rloading: [], //loading遮罩
      foodDomain: food,
      collectionDomain: collection,
      currentlyAvailable: false,
    };
  },
  mounted() {
    this.$bus.$emit("isShowChangeSize");
    this.$bus.$emit("select", "food");
    this.getfoodNavListData();
    this.foodHotspot();
    // categoryId = this.$route.query.categoryId
    keyword = this.$route.query.keyword;
    this.searchFood(categoryId, pageIndex);
  },
  methods: {
    onCompatibility: function (item) {
      var compatibility = getCompatibilityList();
      if (compatibility.length > 0) {
        if (compatibility.some((i) => i.hashKey == item.hashKey && i.type == "3")) {
          this.$message.error("已加入用药审查！");
          return false;
        }
      }
      addCompare(item.hashKey, item.foodMaterialPrescriptionName, 1, "FoodMaterialPrescription");
      this.$message.success("成功加入用药审查！");
      this.$bus.$emit("right", "reload");
    },
    //左侧分类获取
    getfoodNavListData: function () {
      var _this = this;
      _this.foodDomain.Categorys(
        function (data) {
          _this.foodNavList = data.data;
        },
        function (error) {
          console.log("食疗药膳分类请求失败:", error);
        }
      );
    },
    //热门搜索词获取
    foodHotspot: function () {
      var _this = this;
      _this.foodDomain.Hotspot(
        function (data) {
          _this.hots = data.data;
        },
        function (error) {
          console.log("热门搜索词获取请求失败:", error);
        }
      );
    },
    //收藏
    onCollection: function (item) {
      var _this = this;
      _this.collectionDomain.Put(
        "food",
        item.foodMaterialPrescriptionName,
        item.hashKey,
        item.foodMaterialPrescriptionId,
        0,
        function (data) {
          _this.$message({
            type: "success",
            message: "收藏成功!",
          });
        },
        function (err) {
          if (err.status === 400) {
            _this.$message.error("请勿重复收藏!");
          }
        }
      );
    },
    handleOpen(key, keyPath) {},
    handleClose(key, keyPath) {},
    //右侧食疗药膳列表获取
    searchFood: function (cateId, isPage = false) {
      categoryId = cateId;
      var _this = this;
      _this.rloading = _this.openLoading();
      if (!isPage) pageIndex = 1;
      if (cateId == undefined) cateId = 0;
      let params = `/${cateId}/${pageIndex}`;
      if (keyword != "" && keyword != undefined) {
        params = params + `/${keyword}`;
      } else {
        params += "/%20";
      }
      _this.foodDomain.Foods(
        params,
        function (data) {
          _this.foodDetailList = data.data.results;
          _this.total = data.data.dataTotal;
          searchId = data.msg;
          _this.rloading.close();
        },
        function (error) {
          console.log("食疗药膳列表请求失败:", error);
        }
      );
      if (!isPage) {
        this.currentPage = 1;
      }
      // chrome
      document.body.scrollTop = 0;
      // firefox
      document.documentElement.scrollTop = 0;
      // safari
      window.pageYOffset = 0;
    },
    //右侧食疗药膳属性获取
    category: function (data) {
      var categoryName = "";
      if (data.foodMaterialPrescriptionCategory != null) {
        if (data.foodMaterialPrescriptionCategory.Parent != null) {
          categoryName = data.foodMaterialPrescriptionCategory.parent.categoryName + "/";
        }
        categoryName += data.foodMaterialPrescriptionCategory.categoryName;
      }
      return categoryName;
    },
    //分页点击事件
    handleCurrentChange: function (pageNum) {
      this.currentPage = pageNum;
      pageIndex = pageNum;
      this.searchFood(categoryId, true);
      // chrome
      document.body.scrollTop = 0;
      // firefox
      document.documentElement.scrollTop = 0;
      // safari
      window.pageYOffset = 0;
    },
    getCurrentlyAvailable(data) {
      this.currentlyAvailable = data;
    },
    //跳转到详情
    toFoodDetail(hashKey) {
      if (!this.currentlyAvailable) {
        this.$refs.upgradeMsgBox.openTip();
        return;
      }
      this.$router.push({
        path: "/Drug/FoodDetail",
        query: {
          hashKey: hashKey,
        },
      });
    },
  },
  watch: {
    $route() {
      keyword = this.$route.query.keyword;
      this.searchFood(categoryId, pageIndex);
    },
  },
};
</script>
<style scoped>
@import "../../assets/css/header.css";
@import "../../assets/css/zhongyaoItem.css";
@import "../../assets/css/footer.css";
.food-list .food-item {
  position: relative;
  padding: 15px 20px;
  margin-bottom: 10px;
  background: #ffffff;
  border: 1px solid #f5f5f5;
  box-shadow: 3px 0 5px rgba(0, 0, 0, 0.08);
  cursor: pointer;
}

.food-list .food-item:hover {
  box-shadow: 3px 2px 8px rgba(0, 0, 0, 0.2);
}
.resule-item:hover .resule-star {
  opacity: 1;
  transition: all linear 0.5s;
}

.resule-star {
  transition: all linear 0.5s;
  opacity: 0;
  /* margin-right: 30px; */
}

.resule-star i {
  font-size: 26px;
  color: #15aa9d;
}
.food-tit {
  font-size: 18px;
  font-weight: normal;
}
.banner {
    height: 116px;
    background: #b76c12;
}
.food-tit span {
  font-size: 12px !important;
  color: #fff;
  background-color: #b76c12;
  border-radius: 10px;
  height: 18px;
  line-height: 18px;
  margin-left: 10px;
  padding: 0 4px;
  display: inline-block;
  position: relative;
  top: -2px;
}

.food-item p {
  margin-top: 10px;
  color: #666666;
}

.food-item p em {
  font-style: normal;
}

.sc-btn i {
  position: absolute;
  right: 43px;
  top: 15px;
  font-size: 26px;
  color: #b76c12;
  height: 36px;
  line-height: 36px;
  display: block;
  padding: 0 20px;
}
</style>
